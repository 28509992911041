<template>
  <div>
    <v-btn
      :dark="!isBnn && !disabled"
      :color="colorBnn['color-bnn']"
      :disabled="disabled"
      :loading="isLoading"
      class="font-bold"
      block
      depressed
      large
      @click="
        isRequireApprovalCode
          ? $emit('confirm-payment')
          : $emit('submit')
      "
    >
      ยืนยันการชำระเงิน
    </v-btn>

    <v-dialog v-model="dialog" persistent max-width="340">
      <v-card>
        <div class="header-modal-container">
          <div class="header-modal">
            <span class="header-modal-title">
              <div class="d-flex">
                <span class="font-bold text-black">
                  ยืนยันการชำระเงิน
                </span>
              </div>
            </span>
          </div>
          <div class="text-center">
            กรุณากรอกเลขที่ใบเสร็จรับเงิน (Approval Code)
          </div>
        </div>

        <div class="body-modal">
          <v-row v-bind="{ justify: 'end', 'no-gutters': true }">
            <v-col cols="12">
              <v-text-field
                v-model="receipt_no"
                :color="colorBnn['color-bnn']"
                :error="$v.receipt_no.$error"
                :error-messages="
                  $v.receipt_no.$error &&
                    handleErrorMessages('receipt_no')
                "
                label="เลขที่ใบเสร็จรับเงิน (Approval Code)"
                large
              ></v-text-field>
            </v-col>

            <v-col cols="12" class="mt-4">
              <v-btn
                block
                depressed
                large
                class="font-bold"
                :dark="!isBnn"
                :color="colorBnn['color-bnn']"
                :loading="isLoading"
                @click="handleSubmit"
              >
                ยืนยัน
              </v-btn>
            </v-col>

            <v-col cols="12" class="mt-4">
              <v-btn block depressed link large @click="close">
                ยกเลิก
              </v-btn>
            </v-col>
          </v-row>
        </div>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import colorBnn from '@/config/color'
import isBnn from '@/config/isBnn'
import { validationMixin } from 'vuelidate'
import { required } from 'vuelidate/lib/validators'

export default {
  name: 'ConfirmPaymentApprovalCode',
  mixins: [validationMixin],

  props: {
    title: {
      type: String,
      default: 'ยืนยันตัวตน',
    },
    isRequireApprovalCode: {
      type: Boolean,
      default: false,
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },

  components: {},

  computed: {},

  data() {
    return {
      // Import
      colorBnn,
      isBnn,
      // ----------------

      // Data
      dialog: false,
      receipt_no: '',
    }
  },

  validations: {
    receipt_no: {
      required,
      //   valid: value => /^[^0-9a-zA-Z]{6}$/.test(value),
    },
  },

  methods: {
    open() {
      this.dialog = true
    },

    close() {
      this.dialog = false
      this.receipt_no = ''
      this.$v.$reset()
    },

    handleErrorMessages(field) {
      const errors = []
      if (!this.$v[field].$dirty) return errors
      !this.$v[field].required && errors.push('กรุณากรอกข้อมูล')
      //   !this.$v[field].valid &&
      //     errors.push('กรุณากรอกเลขที่ใบเสร็จรับเงิน 6 หลัก')
      return errors
    },

    handleSubmit() {
      this.$v.$touch()

      if (this.$v.$invalid) {
        return
      }

      this.$emit('submit', this.receipt_no)

      this.close()
    },
  },

  beforeDestroy() {
    this.$v.$reset()
  },
}
</script>

<style lang="stylus" scoped>
.header-modal-container
  padding: 16px 16px 0

.header-modal
  display: flex
  justify-content: center
  width: 100%

.body-modal
  padding: 16px

.header-modal-title
  font-size: 24px
  font-weight: bold
  font-family: $font-family-base !important
  align-self: center


.header-modal-close
  cursor: pointer
  color: $color-bnn-gray-light
  align-self: center

.empty-data
	text-align: center
	color: $color-bnn-gray-medium-2
	padding: 48px 0

.link
  width: fit-content
  display: inline
  color: $color-bnn
  text-decoration: underline
  &.disabled
    color: $color-bnn-gray-light
    text-decoration: none

.input-container {
	.input-otp-container {
		display: flex;
		flex-wrap: nowrap;
		gap: 12px;
		justify-content: center;
		margin-bottom: 16px;

		&.is-invalid {
			.input-otp {
				box-shadow: 0 0 0 1px $color-red;
			}
		}

		.input-otp {
			width: 100%;
			height: 68px;
			font-size: 24px;
			text-align: center;
			border: none;
			box-shadow: 0 0 0 1px $color-bnn-gray-light;
			border-radius: 4px;

			&:hover {
				box-shadow: 0 0 0 1px $color-bnn-border;
			}

			&:focus,
			&:focus-visible {
				box-shadow: 0 0 0 2px $color-bnn-border;
				outline: none;
			}

			/* Chrome, Safari, Edge, Opera */
			&::-webkit-outer-spin-button,
			&::-webkit-inner-spin-button {
				-webkit-appearance: none;
				margin: 0;
			}

			/* Firefox */
			&[type=number] {
				-moz-appearance: textfield;
			}
		}
	}
}

.divider-with-text {
	display: flex;
	align-items: center;
	text-align: center;
	color: $color-bnn-gray-light;
	font-size: 14px;

	&::before,
	&::after {
	  content: '';
	  flex: 1;
	  border-bottom: 1px solid #f4f4f4;
	}
	&:not(:empty)::before {
	  margin-right: 16px;
	}
	&:not(:empty)::after {
	  margin-left: 16px;
	}
}

.btn-scan-qr-code {
  font-weight: bold;
  color: $color-dark-gray-medium;

  .v-icon {
	color: $color-bnn-border
  }
}

.desc-seven-club-member {
	text-align: center;
	font-size: 14px;
	color: $color-dark-gray-medium;
}
</style>
